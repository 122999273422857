export const environment = {
  production: false,
  APP_NAME: 'TM Travel Admin Center',
  COMPANY_NAME: 'TMA Technology Group',
  API_URL: 'https://api-dev-tmtrav.tma-swerp.com',
  APP_DEBUG_URL: 'https://localhost:4200',
  REFRESH_TOKEN_KEY: 'refresh_token',
  USER_ROLE_KEY: 'user_role',
  REFRESH_TOKEN_EXPIRY_KEY: 'refresh_token_expiry_date',
  ACCESS_TOKEN_EXPIRY_KEY: 'refresh_token_expiry_date',
  AUTH_STATUS: 'auth_status_admin',
  USER_PROFILE: 'user_profile_admin',
  TOKEN_KEY: 'access_token_admin',
  TOKEN: 'token_admin',
  API_TIMEOUT: 10000,
  languages: ['en', 'vi'],
  socialAuthenIds: {
    google: '802332645501-0aiir74m36114t6v974bcrg4jrf8k1do.apps.googleusercontent.com',
    facebook: '2619489051654463'
  },
  APP_LIST: {
    CONTENT_APPROVAL: 'https://approval-tmtrav-dev.tma-swerp.com',
    USER_ASSIGNMENT: 'https://user-assignment-tmtrav-dev.tma-swerp.com',
    SOCIAL_ADMIN: 'https://admin-tmtrav-dev.tma-swerp.com',
    TRAVEL_ADMIN: 'https://tmtrav-travel-admin-dev.tma-swerp.com',
    SHOP_ADMIN: 'https://tmtrav-shop-admin-dev.tma-swerp.com',
    FOOD_ADMIN: 'https://tmtrav-food-admin-dev.tma-swerp.com',
    CAR_ADMIN: 'https://tmtrav-car-admin-dev.tma-swerp.com',
    HOTEL_ADMIN: 'https://hotel-admin-tmtrav.tma-swerp.com',
    FLIGHT_ADMIN: 'https://flight-admin-tmtrav.tma-swerp.com',
    FINANCE_ADMIN: 'https://tmtrav-finance-dev.tma-swerp.com',
    VOUCHER_ADMIN: 'https://tmtrav-voucher-hub-dev.tma-swerp.com',
    BANNER_ADMIN: 'https://tmtrav-banner-dev.tma-swerp.com',
    CROSS_SALE_ADMIN:'https://tmtrav-cross-sale-dev.tma-swerp.com',
    EMAIL_MARKETING_ADMIN: 'https://marketing.tma-swerp.com',
    TICKET_ADMIN: 'https://ticket.tma-swerp.com',
    CHAT_FAQ_ADMIN: 'https://chat-faq-admin.tma-swerp.com',
    LOYALTY_ADMIN: 'https://loyalty-admin.tma-swerp.com',
  },
  LOYALTY_VISIBLE: true,
};
